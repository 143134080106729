<template>
  <div>

    <Header/>

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp;&nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/vente">Vente</a></li>
          </ol>
        </nav>
      </div>

      <div class="pt-3">


        <div class="container mb-3">
          <div class="card bg-primary rounded-0 rounded-top">
            <div class="py-1 text-center">
              <h6 class="pt-2 text-white text-center">SPANCO</h6>
            </div>
          </div>
          <div class="card rounded-0 rounded-bottom">
            <ul class="list-group">
              <div v-for="(spancoValue, key) in spancoValues" :key="spancoValue">
                <li class="list-group-item d-flex justify-content-between"><span><b>{{key}}</b>{{ spancoValue }}</span>
                  <a :href="'#collapse-' + key" class="ml-2 text-emb" data-bs-toggle="collapse" aria-expanded="false" aria-controls="spanco">
                    <span v-if="spancoOrganismes[key] && spancoOrganismes[key].length > 0" class="badge bg-success">{{ spancoOrganismes[key].length }}</span>
                    <span v-else class="badge bg-secondary">0</span>
                    <i class="fas fa-chevron-down fas-modify ms-3"></i>
                  </a>
                </li>
                <li :id="'collapse-' + key" class="collapse list-group-item p-1">
                  <ul class="ps-0 chat-user-list rounded-0">
                    <div v-for="(organisme) in spancoOrganismes[key]" :key="organisme.id">
                      <li class="p-3 chat-unread">
                        <div class="chat-user-info text-left">

                          <h6 class="text-truncate mb-0">{{ organisme.nom }}</h6>
                        
                          <div class="last-chat">
                            <p class="mb-1">
                              <a v-if="organisme.email" :href="'mailto:' + organisme.email"><span class="badge bg-success">{{ organisme.email }}</span></a>
                            </p>
                            <p class="mb-1">
                              <a v-if="organisme.telStandard" :href="'tel:' + organisme.telStandard"><span class="badge bg-success">{{ organisme.telStandard}}</span></a>
                            </p>
                            <p class="mb-1">
                              <span class="badge " v-bind:class="organisme.contacts.length > 0 ? 'bg-success' : 'bg-secondary'">{{ organisme.contacts.length}} <i class="fas fa-user"></i></span>
                              <span v-if="memos[organisme.id]" class="ms-2 badge " v-bind:class="memos[organisme.id].length > 0 ? 'bg-primary' : 'bg-secondary'">{{ memos[organisme.id].length}} Memos</span>
                              <span v-else class="ms-2 badge bg-secondary">0 Memos</span>
                              <span v-if="key === 'N' && devis[organisme.id]" class="ms-2 badge " v-bind:class="devis[organisme.id].length > 0 ? 'bg-primary' : 'bg-secondary'">{{ devis[organisme.id].length}} Devis</span>
                              <span v-if="(key === 'C' || key === 'O') && commandes[organisme.id]" class="ms-2 badge " v-bind:class="commandes[organisme.id].length > 0 ? 'bg-primary' : 'bg-secondary'">{{ commandes[organisme.id].length}} Commandes</span>

                            </p>
                          </div>
                        </div>
                        <div class="dropstart chat-options-btn">
                          <a :href="'/organismes/' + organisme.id" style="float: right;" class="mb-4"><span class="badge bg-primary"><i class="fas fa-eye"></i></span></a>

                          <a :href="'#collapse-' + organisme.id" class="ml-2 text-emb" data-bs-toggle="collapse" aria-expanded="false" aria-controls="organisme">
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </li>
                      <div :id="'collapse-' + organisme.id" class="collapse list-group-item">
                        <div class="accordion" :id="'accordion-' + organisme.id">

                          <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-memos-'+organisme.id" aria-expanded="true" aria-controls="collapseOne">
                                Mémos
                              </button>
                            </h2>
                            <div :id="'collapse-memos-'+organisme.id" class="accordion-collapse collapse" aria-labelledby="headingOne" :data-bs-parent="'#accordion-' + organisme.id">
                              <div class="accordion-body">
                                <div class="small" style="float: right;"><a class="shadow badge bg-success" href="#" data-bs-toggle="modal" :data-bs-target="'#newMemo'" @click="setMemoInfo('Organisme', organisme.id, organisme.code)"><i class="bi bi-plus-lg"></i></a></div>
                                  <br>
                                  <div v-if="memos[organisme.id]">
                                    <ul class="ps-0" style="border: none!important;">
                                        <li v-for="memo in memos[organisme.id]" :key="memo.id" class="p-1 chat-unread">
                                          <a class="" v-bind:href="'/memos/' + memo.id">
                                            <div class="chat-user-info text-left w-100">
                                              <h6 class="text-truncate mb-0">
                                                {{ memo.code }} - {{ memo.nom }}
                                              </h6>
                                              <div class="last-chat w-100">
                                                <MemoStatut :type="memo.type" />
                                                <span class="badge bg-primary">{{ memo.date|formatDate }} à {{ memo.heure }}</span>
                                                <MemoStatut :priorite="memo.priorite" />
                                              </div>
                                            </div>
                                          </a>
                                          <div class="dropstart chat-options-btn">
                                            <a :href="'/memos/' + memo.id" class="ml-2">
                                              <i class="fas fa-chevron-right"></i>
                                            </a>
                                          </div>
                                        </li>
                                    </ul>
                                  </div>
                                  <div v-else class="alert alert-warning w-100">Aucun mémo !</div>
                              </div>
                            </div>
                          </div>

                          <div v-if="key === 'N'" class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-devis-'+organisme.id" aria-expanded="false" :aria-controls="'#collapse-devis-'+organisme.id">
                                Devis
                              </button>
                            </h2>
                            <div :id="'collapse-devis-'+organisme.id" class="accordion-collapse collapse" aria-labelledby="headingTwo" :data-bs-parent="'#accordion-' + organisme.id">
                              <div class="accordion-body">
                                  <div v-if="devis[organisme.id]">
                                    <ul class="ps-0" style="border: none!important;">
                                        <li v-for="devis in devis[organisme.id]" :key="devis.id" class="p-1 chat-unread">
                                          <a class="" href="#">
                                            <div class="chat-user-info text-left w-100">
                                              <h6 class="text-truncate mb-0">
                                                {{ devis.code }} - {{ devis.nom }}
                                              </h6>
                                              <div class="last-chat w-100">
                                                
                                              </div>
                                            </div>
                                          </a>
                                          <div class="dropstart chat-options-btn">
                                            <a :href="'#'" class="ml-2">
                                              <i class="fas fa-chevron-right"></i>
                                            </a>
                                          </div>
                                        </li>
                                    </ul>
                                  </div>
                                  <div v-else class="alert alert-warning w-100">Aucun devis !</div>
                              </div>
                            </div>
                          </div>



                          <div v-if="key === 'C' || key === 'O'" class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-commandes-'+organisme.id" aria-expanded="false" :aria-controls="'#collapse-commandes-'+organisme.id">
                                Commandes
                              </button>
                            </h2>
                            <div :id="'collapse-commandes-'+organisme.id" class="accordion-collapse collapse" aria-labelledby="headingThree" :data-bs-parent="'#accordion-' + organisme.id">
                              <div class="accordion-body">
                                  <div v-if="commandes[organisme.id]">
                                    <ul class="ps-0" style="border: none!important;">
                                        <li v-for="commande in commandes[organisme.id]" :key="commande.id" class="p-1 chat-unread">
                                          <a class="" v-bind:href="'#'">
                                            <div class="chat-user-info text-left w-100">
                                              <h6 class="text-truncate mb-0">
                                                {{ commande.code }} - {{ commande.nom }}
                                              </h6>
                                              <div class="last-chat w-100">
                                                
                                              </div>
                                            </div>
                                          </a>
                                          <div class="dropstart chat-options-btn">
                                            <a :href="'#'" class="ml-2">
                                              <i class="fas fa-chevron-right"></i>
                                            </a>
                                          </div>
                                        </li>
                                    </ul>
                                  </div>
                                  <div v-else class="alert alert-warning w-100">Aucune commande !</div>
                              </div>
                            </div>
                          </div>


                        </div>
                        
                      </div>
                      
                    </div>
                  </ul>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div class="modal fade" :id="'newMemo'" tabindex="-1" aria-labelledby="modalMemoLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <button id="closeModalMemo" class="btn btn-close p-1 ms-auto" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <MemoForm :srcEntity="srcEntity" :srcCode="srcCode" :srcUuid="srcUuid" />
            </div>
            <div class="modal-footer">
              <button class="btn btn-sm btn-secondary text-white" type="button" data-bs-dismiss="modal">Annuler</button>
            </div>
          </div>
        </div>
      </div>
        

      <div class="pb-3"></div>

    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';
import MemoStatut from '../components/MemoStatut.vue';
import MemoForm from '../components/MemoForm.vue';

export default {
  name: 'Vente',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      spancoValues: [],
      spancoOrganismes: [],
      memos: [],
      devis: [],
      commandes: [],
      srcEntity: null,
      srcUuid: null,
      srcCode: null
    }
  },
  components: {
    Header,
    Footer,
    MemoStatut,
    MemoForm,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    this.spancoValues = {'S':'uspect (cible)','P':'rospect (lead)', 'A': 'pproche (RDV 1)', 'N': 'égociation (RDV 2)', 'C': 'onclusion (signature)', 'O':'rdre d\'achat'};

    await UserService.getSpanco().then((response) => {
          if (response.data['hydra:member'][0] != undefined) {
            this.spancoOrganismes = response.data['hydra:member'][0];
          }
          if (response.data['hydra:member'][1] != undefined) {
            this.memos = response.data['hydra:member'][1];
          }
          if (response.data['hydra:member'][2] != undefined) {
            this.devis = response.data['hydra:member'][2];
          }
          if (response.data['hydra:member'][3] != undefined) {
            this.commandes = response.data['hydra:member'][3];
          }
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      
  },
  mounted() {
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    setMemoInfo(srcEntity, id, code) {
      this.srcEntity = srcEntity;
      this.srcCode = code;
      this.srcUuid = id;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 80px;
    vertical-align: middle;
    -o-object-fit: cover;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}
</style>
