<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp;&nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/entreprise">Mon compte</a></li>
          </ol>
        </nav>
      </div>



      <div class="container mb-3">
        <div class="card user-info-card chat-user-list rounded-0">
          <div class="card-body d-flex">
            <div class="user-info text-left">
              <h6 class="mb-1 text-primary">{{ action.code }} - {{ action.nom }}</h6>
            </div>
          </div>
        </div>

        <div class="card rounded-0 rounded-bottom">
          <button v-if="action.isClosed === false" id="btn-cloture" class="btn btn-danger btn-sm" @click.prevent="cloture">Clôturer l'action</button>
          <button v-if="action.isClosed === true" id="btn-cloture" class="btn btn-success btn-sm" @click.prevent="decloture">Réouvrir l'action</button>
          <ul class="list-group text-left">
            <li class="list-group-item"><strong>Source : </strong> {{ action.sourceCode }}</li>
            <li v-if="action.demandeur" class="list-group-item"><strong>Demandeur : </strong> {{ action.demandeur.prenom }} {{ action.demandeur.nom }}</li>
            <li v-if="action.pilote" class="list-group-item"><strong>Pilote : </strong> {{ action.pilote.prenom }} {{ action.pilote.nom }}</li>
            <li class="list-group-item"><strong>Date début : </strong> {{ action.dateDebut|formatDate }}</li>
            <li class="list-group-item"><strong>Echéance : </strong> {{ action.echeance|formatDate }}</li>
            <li class="list-group-item"><strong>Avancement : </strong> {{ action.avancement }}%</li>
            <li v-if="action.detail" class="list-group-item"><strong>Détail : </strong> {{ action.detail }}</li>
            <li class="list-group-item"><strong>Type : </strong> <ActionStatut :type="action.type"/></li>
            <li class="list-group-item"><strong>Statut : </strong>  <ActionStatut :statut="action.statut"/></li>
            <li v-if="action.isClosed === true" class="list-group-item"><strong>Clôturée : </strong> <span class="badge bg-success ms-2">OUI</span></li>
            <li v-else class="list-group-item"><strong>Clôturée : </strong> <span class="badge bg-danger ms-2">NON</span></li>

          </ul>
        </div>

      </div>




    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';
import ActionStatut from '../components/ActionStatut.vue';

export default {
  name: 'Action',
  data: function () {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      action: [],
    }
  },
  components: {
    Header,
    Footer,
    ActionStatut,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    if (this.loggedIn) {
      await UserService.getAction(this.$route.params.id).then((response) => {
        this.action = response.data;
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
  },
  mounted() {

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    cloture: async function () {
      let data = {isClosed: true, isDone: true, statut: 'closed'}
      await UserService.patchAction(this.action.id, data).then(
          response => {
            this.action = response.data;
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
    },
    decloture: async function () {
      let data = {isClosed: false, isDone: false, statut: 'validated'}
      await UserService.patchAction(this.action.id, data).then(
          response => {
            this.action = response.data;
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
    },

  },
  watch: {
    '$route.query.type': {
      handler: function (type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}

.avatar-img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 80px;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}</style>
