<template>
  <span>
      <span v-if="priorite == 'todo'" class="badge bg-danger">Urgent et important (à faire ASAP)</span>
      <span v-if="priorite == 'toDelegate'" class="badge bg-warning">Urgent mais pas important (à déléguer)</span>
      <span v-if="priorite == 'toPlan'" class="badge bg-primary">Pas urgent mais important (à planifier)</span>
      <span v-if="priorite == 'toForget'" class="badge bg-secondary">Pas urgent et pas important (à oublier)</span>
      
      <span v-if="type == 'emailSend'" class="badge bg-primary me-2">Email envoyé</span>
      <span v-if="type == 'emailReceived'" class="badge bg-primary me-2">Email reçu</span>
      <span v-if="type == 'smsSend'" class="badge bg-primary me-2">SMS envoyé</span>
      <span v-if="type == 'smsReceived'" class="badge bg-primary me-2">SMS reçu</span>
      <span v-if="type == 'telSend'" class="badge bg-primary me-2">Appel tél. sortant</span>
      <span v-if="type == 'telReceived'" class="badge bg-primary me-2">Appel tél. entrant</span>
      <span v-if="type == 'formContact'" class="badge bg-primary me-2">Formulaire de contact</span>
      <span v-if="type == 'rdv'" class="badge bg-primary me-2">Rendez-vous</span>
      <span v-if="type == 'other'" class="badge bg-primary me-2">Autre</span>
  </span>
</template>

<script>

export default {
  name: 'MemoStatut',
  props: ['priorite', 'type'],
  data: function() {
    return {
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<style>
.header-title {
    color: #153760;
    padding-top: 0.4rem;
    font-weight: bold;
}
h6 {
    font-size: 1.2rem;
    font-weight: bolder;
}
</style>