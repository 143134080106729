<template>
  <div>

    <Header/>

    <div class="page-content-wrapper">

      <div class="pt-3">

        <div class="container">
          <DeplacementValidationForm :id="id" />
        </div>
        


      </div>

      <div class="pb-3"></div>

    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import DeplacementValidationForm from '@/components/DeplacementValidationForm.vue'
//import UserService from '../services/user.service';

export default {
  name: 'Home',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      editing: false,
      formType: '',
      isStartDeplacement: 'false',
      id: null,
    }
  },
  components: {
    Header,
    Footer,
    DeplacementValidationForm,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }
    
    this.id = this.$route.params.id;

    this.isStartDeplacement = localStorage.getItem('isStartDeplacement');
  },
  mounted() {
    this.isStartDeplacement = localStorage.getItem('isStartDeplacement');
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 80px;
    vertical-align: middle;
    -o-object-fit: cover;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}
</style>
