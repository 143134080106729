import { render, staticRenderFns } from "./Vente.vue?vue&type=template&id=2398420c&"
import script from "./Vente.vue?vue&type=script&lang=js&"
export * from "./Vente.vue?vue&type=script&lang=js&"
import style0 from "./Vente.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports