<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp;
                  &nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/crm">Annuaire</a></li>
          </ol>
        </nav>
      </div>



      <div class="container mb-3">
        <div class="card">
          <div class="minimal-tab">
            <ul class="nav nav-tabs p-1" id="affanTab2" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="btn active" id="client-tab" data-bs-toggle="tab" data-bs-target="#client" type="button"
                  role="tab" aria-controls="client" aria-selected="true">Client</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="btn" id="organisme-tab" data-bs-toggle="tab" data-bs-target="#organisme" type="button"
                  role="tab" aria-controls="organisme" aria-selected="false">Organismes</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="btn" id="contacts-tab" data-bs-toggle="tab" data-bs-target="#contacts" type="button"
                  role="tab" aria-controls="contacts" aria-selected="false">Contacts</button>
              </li>
            </ul>

            <div class="tab-content rounded-lg" id="affanTab2Content" style="border: none;">
              <div class="tab-pane fade show active" id="client" role="tabpanel" aria-labelledby="client-tab">
                <div class="container p-0">
                  <div class="card user-info-card chat-user-list rounded-0">
                    <div class="card-body d-flex align-items-center">
                      <div class="row">
                        <div class="col-4">
                          <div class="user-profile me-3" style="max-height: 3rem; max-width: 3rem;">
                            <img v-if="client.logo" v-bind:src="$urlUploads + client.logo"
                              class="avatar-img rounded-circle" alt="logo">
                            <img v-else src="/img/no-image.png" class="avatar-img rounded-circle" alt="logo">
                          </div>
                        </div>
                        <div class="col-8  d-flex align-items-center">
                          <div class="user-info text-center">
                            <h6 class="mb-1 text-primary">{{ client.nom }}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card rounded-0 rounded-bottom">
                    <ul class="list-group text-left">
                      <li class="list-group-item"><strong>Code : </strong> {{ client.code }}</li>
                      <li class="list-group-item"><strong>SIRET : </strong> {{ client.siret }}</li>
                      <li v-if="client.particulierEmail"
                        class="list-group-item d-flex align-items-center justify-content-between pe-1">
                        <div>
                          <strong>Email : </strong> {{ client.particulierEmail }}
                        </div>
                        <div>
                          <a v-bind:href="'mailto:' + client.particulierEmail">
                            <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                                class="fas fa-envelope"></i></span></a>
                        </div>
                      </li>
                      <li v-if="client.particulierTel"
                        class="list-group-item d-flex align-items-center justify-content-between pe-1">
                        <div>
                          <strong>Tél. : </strong> {{ client.particulierTel }}
                        </div>
                        <div>
                          <a v-bind:href="'tel:' + client.particulierTel">
                            <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                                class="fas fa-phone-alt"></i></span></a>
                          <a v-bind:href="'sms:' + client.particulierTel">
                            <span class="badge bg-primary rounded-pill py-2"
                              style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;">SMS</span></a>
                        </div>
                      </li>
                      <li class="list-group-item d-flex">
                        <div class="col-3"><b>Adresse : </b></div>
                        <div class="col-8 text-left">
                          <span>{{ client.adresse1 }}<br></span>
                          <span v-if="client.adresse2">{{ client.adresse2 }}<br></span>
                          <span>{{ client.cp }} {{ client.ville }}</span>
                        </div>
                        <div class="col-1 text-right">
                          <a v-if="linkToWaze" :href="linkToWaze">
                            <span class="badge bg-primary rounded-pill py-2"
                              style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;"><i style="font-size: 15px;" class="fab fa-waze"></i></span>
                            </a>
                        </div>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

              <div class="tab-pane fade" id="organisme" role="tabpanel" aria-labelledby="organisme-tab">
                <div class="container p-0">
                  <ul v-if="organismes.length > 0" class="ps-0 chat-user-list rounded-0">

                    <li v-for="(organisme, index) in organismes" :key="organisme.id" class="p-3 chat-unread"
                      v-bind:class="{ 'rounded-bottom': index === (organismes.length - 1) }">
                      <a class="d-flex" v-bind:href="'/organismes/' + organisme.id">
                        <!-- Info -->
                        <div class="chat-user-info text-left">
                          <h6 class="text-truncate mb-0">{{ organisme.nom }}</h6>
                          <div class="last-chat">
                            <p class="mb-1">
                              <span class="badge bg-success" v-if="organisme.email">{{ organisme.email }}</span>
                            </p>
                            <p class="mb-1">
                              <span class="badge bg-success" v-if="organisme.telStandard">{{ organisme.telStandard
                              }}</span>
                            </p>
                          </div>
                        </div>
                      </a>
                      <!-- Options -->
                      <div class="dropstart chat-options-btn">
                        <a v-bind:href="'/organismes/' + organisme.id" class="">
                          <i class="fas fa-chevron-right"></i>
                        </a>
                      </div>
                    </li>



                  </ul>
                  <div v-else class="card bg-danger rounded-0 rounded-bottom">
                    <div class="card-body text-center py-3">
                      <h6 class="mb-0 text-white line-height-1">{{ listTitle }}</h6>
                    </div>
                  </div>
                </div>
              </div>


              <div class="tab-pane fade" id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
                <div class="top-products-area" style="background-color: #e2e9fe; border: #e2e9fe;">
                  <div class="container p-0" style="background-color: #e2e9fe; border: #e2e9fe;">
                    <ContactsList v-bind:contacts="contacts" v-bind:listTitle="listTitle" />
                    
                  </div>
                </div>

              </div>

              </div>
            </div>
          </div>
        </div>




      </div>

      <Footer />

    </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactsList from '@/components/ContactsList.vue'
import UserService from '../services/user.service';

export default {
  name: 'Client',
  data: function () {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      client: [],
      organismes: [],
      contacts: [],
      listTitle: 'Recherche en cours',
      linkToWaze: null,
    }
  },
  components: {
    Header,
    Footer,
    ContactsList,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    if (this.loggedIn) {
      // clients
      await UserService.getClient(this.$route.params.id).then((response) => {
        this.client = response.data;
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

      if (this.client.adresse1) {
        let formatedAdresse = this.client.adresse1.replaceAll(' ', '%20');
        let formatedVille = this.client.ville.replaceAll(' ', '%20');
        this.linkToWaze = 'https://waze.com/ul?q=' + formatedAdresse + '%20' + formatedVille;
      }

      // organismes
      await UserService.getOrganismesByClient(this.$route.params.id).then((response) => {
        this.organismes = response.data['hydra:member'];
        let total = response.data['hydra:totalItems'];
        if (total > 1) {
          this.listTitle = '';
        }
        if (total === 0) {
          this.listTitle = 'Aucun organisme associé !';
        }
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

      // contacts
      await UserService.getContactsByClient(this.$route.params.id).then((response) => {
        this.contacts = response.data['hydra:member'];
        let total = response.data['hydra:totalItems'];
        if (total > 1) {
          this.listTitle = '';
        }
        if (total === 0) {
          this.listTitle = 'Aucun contact associé !';
        }
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
  },
  mounted() {

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

  },
  watch: {
    '$route.query.type': {
      handler: function (type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}

.avatar-img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 80px;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}</style>
