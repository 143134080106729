<template>
    <!-- Login Wrapper Area -->
    <div class="login-wrapper d-flex align-items-center justify-content-center" style="margin-top:0;padding-top:0;">
      <div class="custom-container">

        <!-- logo -->
        <div class="text-center">
            <img class="login-intro-img" src="/img/myBusiness.png" alt="logo">
        </div>
        
        <!-- login form -->
         <div class="pb-3"></div>

            <div class="register-form mt-3">
              <h6 class="mb-3 text-center text-rbo">Identification</h6>

              <FormulateForm
                name="loginForm"
                v-model="user"
                @submit="submitHandler"
              >
                <FormulateInput
                  name="username"
                  type="email"
                  placeholder="Votre pseudo ou email"
                  validation="required|email"
                  :validation-messages="{
                    required: 'Veuillez indiquer votre email...',
                    email: 'Veuillez indiquer une adresse email valide...'
                  }"
                />

                <FormulateInput
                  name="password"
                  type="password"
                  placeholder="Votre mot de passe"
                  validation="required"
                  :validation-messages="{
                    required: 'Veuillez indiquer votre mot de passe...'
                  }"
                />

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <FormulateInput
                  type="submit"
                  label="Valider"
                  input-class="btn btn-primary w-100 text-white"
                />
                
              </FormulateForm>

            </div>
      </div>
    </div>
  
</template>

<script>
import router from '../router'
import User from '../models/user';
import UserService from '../services/user.service';

export default {
  name: 'Login',
  components: {
  },
  data () {
    return {
      error: null,
      user: new User('', '')
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {

  },
  methods: {
    submitHandler() {
      this.$store.dispatch('auth/login', this.user).then(
        async () => {
          await UserService.getUserMe().then(
            response => {
              localStorage.setItem('userData', response.data);
            },
            error => {
              this.content = (error.response && error.response.data) || error.message || error.toString();
            }
          );
          router.push('/home');
        },
        error => {
          if (error.response.data.code == 401) {
            this.error = "Identifiants invalides...";
          }
        }
      );
    },
  },
}
</script>

<style>
ul.form-text.text-danger {
    color: crimson !important;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    
}
.formulate-input-error {
  padding-top: 0.3rem;
}
</style>