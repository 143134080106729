<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp;
                  &nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/vente">Vente</a></li>
            <li class="breadcrumb-item"><a href="/spanco">Spanco</a></li>
          </ol>
        </nav>
      </div>



      <div class="container mb-3">
        <div class="card user-info-card chat-user-list rounded-0">
          <div class="card-body d-flex align-items-center justify-content-center">
            <div class="user-info text-center">
              <h6 class="mb-1 text-primary">{{ memo.code }} - {{ memo.nom }}</h6>
            </div>
          </div>
        </div>

        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group text-left">
            <li class="list-group-item"><strong>Date : </strong> {{ memo.date|formatDate }}</li>
            <li class="list-group-item"><strong>Type : </strong> <MemoStatut :type="memo.type" /></li>
            <li class="list-group-item"><strong>Auteur : </strong> {{ memo.auteur.prenom }} {{ memo.auteur.nom }}</li>
            <li class="list-group-item"><strong>Pilote : </strong> {{ memo.pilote.prenom }} {{ memo.pilote.nom }}</li>
            <li class="list-group-item"><strong>Priorité : </strong> <MemoStatut :priorite="memo.priorite" /></li>
            <li class="list-group-item"><strong>Echéance : </strong> {{ memo.echeance|formatDate }}</li>
          </ul>
        </div>


      </div>




    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';
import MemoStatut from '../components/MemoStatut.vue';

export default {
  name: 'Memo',
  data: function () {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      memo: [],
      listTitle: 'Recherche en cours'
    }
  },
  components: {
    Header,
    Footer,
    MemoStatut,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    if (this.loggedIn) {
      await UserService.getMemo(this.$route.params.id).then((response) => {
        this.memo = response.data;
        console.log(this.memo)
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
  },
  mounted() {

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

  },
  watch: {
    '$route.query.type': {
      handler: function (type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}

.avatar-img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 80px;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}</style>
