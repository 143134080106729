<template>
    <div id="remForm">
          <div class="card mb-3">
            <div class="card-body pt-0 pb-0">
  
              <!-- form -->
              <div class="">
                <h6 class="mb-4 text-center text-primary">Nouveau mémo</h6>
  
                <FormulateForm @submit="validationForm">

                  <FormulateInput
                    type="datepicker"
                    language="fr"
                    name="date"
                    label="Date"
                    format="dd/MM/yyyy"
                    v-model="memo.date"
                  />
  
                  <div class="text-left mt-4 mb-1"><label>Pilote</label></div>
                  <Multiselect
                    v-model="memo.pilote" 
                    :options="formSelectPilotes"
                    :searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    :custom-label="formSelectPilotes.label"
                    label="label"
                    track-by="value"
                    placeholder="Selectionner le pilote"
                    :internal-search="false"
                    @search-change="searchChangePilote"
                  ></Multiselect>

                  <div class="mb-4"></div>

                  <FormulateInput
                    type="text"
                    name="sample"
                    label="Nom"
                    validation="required"
                    :validation-messages="{
                        required: 'Le nom du mémo est obligatoire'
                      }" 
                    v-model="memo.nom"
                  />

                  <div class="text-left mt-4 mb-1"><label>Type</label></div>
                  <Multiselect
                    v-model="memo.type" 
                    :options="formSelectTypes"
                    :searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    :custom-label="formSelectTypes.label"
                    label="label"
                    track-by="value"
                    placeholder="Selectionner le type"
                    :internal-search="false"
                  ></Multiselect>

                  <div class="mb-4"></div>

                  <FormulateInput
                    type="textarea"
                    label="Détail"
                    v-model="memo.detail"
                  />

                  <div class="text-left mt-4 mb-1"><label>Priorité</label></div>
                  <Multiselect
                    v-model="memo.priorite" 
                    :options="formSelectPriorites"
                    :searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    :custom-label="formSelectPriorites.label"
                    label="label"
                    track-by="value"
                    placeholder="Selectionner la priorité"
                    :internal-search="false"
                  ></Multiselect>

                  <div class="mb-4"></div>


                  <FormulateInput
                    type="datepicker"
                    language="fr"
                    name="date"
                    label="Echéance"
                    format="dd/MM/yyyy"
                    v-model="memo.echeance"
                  />

                  <div class="form-check text-left">
                    <input v-model="memo.setNullSpanco" class="form-check-input" id="checkboxSetNull" type="checkbox" value="">
                    <label class="form-check-label" for="checkboxSetNull">Retiré du SPANCO</label>
                  </div>

                  <div class="form-check text-left">
                    <input v-model="memo.setNextSpanco" class="form-check-input" id="checkboxSetNextSpanco" type="checkbox" value="">
                    <label class="form-check-label" for="checkboxSetNextSpanco">Passé à l'étape suivante</label>
                  </div>


  
                  <div v-if="error" class="alert alert-warning w-100">
                    <i class="fas fa-exclamation-triangle"></i>
                    {{error}}
                  </div>
                  
                  <FormulateInput
                    type="submit"
                    label="Valider"
                    id="buttonMemo"
                    input-class="btn btn-success w-100 text-white mt-3"
                  />
                  
                </FormulateForm>
  
              </div>
  
            </div>
          </div>
  
    </div>
  </template>
  
  <script>
  //import router from '../router'
  import UserService from '../services/user.service'
  import Multiselect from 'vue-multiselect'
  
  var temp = Object.freeze({
    srcEntity: null,
    srcUuid: null,
    srcCode: null,
    pilote: null,
    nom: null,
    detail: null,
    date: new Date(),
    type: null,
    echeance: null,
    priorite: null,
    setNullSpanco: false,
    setNextSpanco: false,
  });
  
  export default {
    name: 'Form',
    components: {
      Multiselect,
    },
    props: [
      'srcEntity',
      'srcCode',
      'srcUuid',
  ],
    data () {
      return {
        userData: [],
        error: null,
        memo: Object.assign({}, temp),
        formSelectPilotes: [],
        formSelectTypes: [],
        formSelectPriorites: [],
      }
    },
    async created() {
      this.userData = JSON.parse(localStorage.getItem('userData'));
      this.memo.pilote = { value: this.userData.user.id, label: this.userData.user.firstName + ' ' +  this.userData.user.lastName};

      await UserService.getMemoPilotes().then((response) => {
        this.formSelectPilotes = response.data['hydra:member'];
        localStorage.setItem('formPilotes', JSON.stringify(response.data));
      },
      error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
      }
      );

      this.formPilotes = JSON.parse(localStorage.getItem('formPilotes'));

      this.formSelectTypes = [
        {value: 'emailSend', label: 'Email envoyé'},
        {value: 'emailReceived', label: 'Email reçu'},
        {value: 'smsSend', label: 'SMS envoyé'},
        {value: 'smsReceived', label: 'SMS reçu'},
        {value: 'telSend', label: 'Appel tél. sortant'},
        {value: 'telReceived', label: 'Appel tél. entrant'},
        {value: 'formContact', label: 'Formulaire de contact'},
        {value: 'rdv', label: 'Rendez-vous'},
        {value: 'other', label: 'Autre'},
      ];

      this.formSelectPriorites = [
        {value: 'todo', label: 'Urgent et important (à faire ASAP)'},
        {value: 'toDelegate', label: 'Urgent mais pas important (à déléguer)'},
        {value: 'toPlan', label: 'Pas urgent mais important (à planifier)'},
        {value: 'toForget', label: 'Pas urgent et pas important (à oublier)'},
      ]
    },
    watch: {
      type(val) {
        if (val == 'add') {
          this.memo = Object.assign({}, temp);
        }
      }
    },
    mounted() {
    },
    methods: {
      searchChangePilote(search) {
          this.formSelectPilotes = search
              ? (this.formSelectPilotes.filter(option => this.normalizedContains(search, option.label)))
              : this.formPilotes['hydra:member'];
      },
      removeDiacritics(text) {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      },
      normalizedContains(needle, haystack) {
        const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
        return regExp.test(this.removeDiacritics(haystack));
      },
      async reload() {
        this.$router.go();
      },
      async validationForm() {
        const memo = {
          srcEntity: this.srcEntity,
          srcUuid: this.srcUuid.toString(),
          srcCode: this.srcCode,
          pilote: 'api/users/' + this.memo.pilote.value,
          nom: this.memo.nom,
          detail: this.memo.detail,
          date: this.memo.date,
          type: this.memo.type.value,
          echeance: this.memo.echeance,
          priorite: this.memo.priorite.value,
        };
        //console.log(this.memo.pilote, memo)
        
        UserService.postMemo(memo).then(
          response => {
            UserService.modifyOrganismeByMemo(response.data.id, this.memo.setNextSpanco, this.memo.setNullSpanco).then(
              response => {
                console.log(response)
              },
              error => {
                this.content = (error.response && error.response.data) || error.message || error.toString();
              }
            )
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
          
        );
        document.getElementById('closeModalMemo').click();
        this.reload();
      }
    }
  }
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  