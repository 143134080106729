<template>
  <div>

    <Header/>

    <div class="page-content-wrapper">

      <div class="pt-3">

        <div class="row mb-3">
            <div class="col-6 ps-4">
                <div class="card"  style="height: 156px;">
                  <div class="card-body">
                    <a href="/spanco">
                      <div class="feature-card mx-auto text-center">
                        <div class="card mx-auto bg-success"><i class="fas fa-stream" style="font-size: 30px; color: white;"></i></div>
                        <p class="mb-0 text-success" style="font-size: 16px;"><b>SPANCO</b></p>
                      </div>
                    </a>
                  </div>
                </div>
            </div>
            <div class="col-6 pe-4">
              <div class="card" style="height: 156px;">
                <div class="card-body">
                  <a href="/clients" class="">
                    <div class="feature-card mx-auto text-center">
                      <div class="card mx-auto bg-primary"><i class="fas fa-handshake" style="font-size: 30px; color: white;"></i></div>
                      <p class="mb-0 text-primary" style="font-size: 16px;"><b>Clients</b></p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-6 ps-4">
                <div class="card" style="height: 156px;">
                  <div class="card-body">
                    <a href="/organismes/prospects/particuliers" class="">
                      <div class="feature-card mx-auto text-center">
                        <div class="card mx-auto bg-primary"><i class="fas fa-user" style="font-size: 30px; color: white;"></i></div>
                        <p class="mb-0 text-primary" style="font-size: 16px;"><b>Prospects particuliers</b></p>
                      </div>
                    </a>
                  </div>
                </div>
            </div>
            <div class="col-6 pe-4">
              <div class="card" style="height: 156px;">
                <div class="card-body">
                  <a href="/organismes/prospects/entreprises" class="disabled" disabled>
                    <div class="feature-card mx-auto text-center">
                      <div class="card mx-auto bg-primary"><i class="fas fa-briefcase" style="font-size: 30px; color: white;"></i></div>
                      <p class="mb-0 text-primary" style="font-size: 16px;"><b>Prospects entreprises</b></p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
        </div>

        <!--
        <div class="row mb-3">
            <div class="col-6 ps-4">
                <div class="card">
                  <div class="card-body">
                    <a href="#" class="disabled" disabled>
                      <div class="feature-card mx-auto text-center">
                        <div class="card mx-auto bg-secondary"><i class="fas fa-calendar" style="font-size: 30px; color: white;"></i></div>
                        <p class="mb-0 text-secondary" style="font-size: 16px;"><b>Abonnement</b></p>
                      </div>
                    </a>
                  </div>
                </div>
            </div>
            <div class="col-6 pe-4">
              <div class="card">
                <div class="card-body">
                  <a href="#" class="disabled" disabled>
                    <div class="feature-card mx-auto text-center">
                      <div class="card mx-auto bg-secondary"><i class="fas fa-euro-sign" style="font-size: 30px; color: white;"></i></div>
                      <p class="mb-0 text-secondary" style="font-size: 16px;"><b>Factures</b></p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
        </div>
        -->
        


      </div>

      <div class="pb-3"></div>

    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
//import UserService from '../services/user.service';

export default {
  name: 'Vente',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      date: null,
      editing: false,
      formType: '',
      isNewVersion: false,
      newVersion: null,
    }
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }
  },
  mounted() {
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 80px;
    vertical-align: middle;
    -o-object-fit: cover;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}
</style>
