<template>
        <div v-if="contacts.length > 0" class="row">

            <div v-for="(contact) in contacts" :key="contact.id" class="col-12 mt-3">
                <div class="card single-product-card p-1">
                    <div class="card-body p-1">
                        <div class="user-info text-left">
                            <a class="product-title d-block text-truncate text-primary"
                                v-bind:href="'/contacts/' + contact.id">
                                <h6 class="mb-1 text-primary">{{ contact.prenom }} {{ contact.nom }}</h6>
                                <p>{{ contact.fonction }}</p>
                            </a>
                        </div>

                        <div v-if="contact.email" class="text-left mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="small">{{ contact.email }}</div>
                                <div>
                                    <a v-bind:href="'mailto:' + contact.email">
                                        <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                                                class="fas fa-envelope"></i></span></a>
                                </div>
                            </div>
                        </div>

                        <div v-if="contact.telBureau" class="text-left mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="small">{{ contact.telBureau }}</div>
                                <div>
                                    <a v-bind:href="'tel:' + contact.telBureau">
                                        <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                                                class="fas fa-phone-alt"></i></span></a>
                                    <a v-bind:href="'sms:' + contact.telBureau">
                                        <span class="badge bg-primary rounded-pill py-2"
                                            style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;">SMS</span></a>
                                </div>
                            </div>
                        </div>

                        <div v-if="contact.telMobile" class="text-left mt-2">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="small">{{ contact.telMobile }}</div>
                                <div>
                                    <a v-bind:href="'tel:' + contact.telMobile">
                                        <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem; width: 45px;"><i
                                                class="fas fa-phone-alt"></i></span></a>
                                    <a v-bind:href="'sms:' + contact.telMobile">
                                        <span class="badge bg-primary rounded-pill py-2"
                                            style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;">SMS</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div v-else-if="contacts.length == 0" class="card bg-danger rounded-0 rounded-bottom">
            <div class="card-body text-center py-3">
                <h6 class="mb-0 text-white line-height-1">{{ listTitle }}</h6>
            </div>
        </div>

</template>
  
<script>
export default {
    name: 'ContactsList',
    props: {
        contacts: Array,
        listTitle: String,
        //isSearch: Boolean,
        //urlUploads: String
    }
}
</script>