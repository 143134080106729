<template>
  <div>

    <!-- Footer Nav -->
    <div class="footer-nav-area" id="footerNav">
      <div class="container px-0">
        <!-- Footer Content -->
        <div class="footer-nav position-relative">
          <ul class="h-100 d-flex align-items-center justify-content-between ps-0">
            <li>
                <a href="/home"><i class="fas fa-home"></i><span>Accueil</span></a>
            </li>
            <li>
                <a href="/entreprise"><i class="fas fa-briefcase"></i><span>Mon compte</span></a>
            </li>
            <li>
                <a href="/crm"><i class="fas fa-users"></i><span>Annuaire</span></a>
            </li>
            <li>
                <a href="/vente"><i class="fas fa-euro-sign"></i><span>Ventes</span></a>
            </li>
            <li>
                <a href="/vehicules"><i class="fas fa-car"></i><span>Véhicules</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isMember: true,
      loggedIn: this.$store.state.auth.status.loggedIn,
    }
  },
  created() {
    if(localStorage.getItem('userData')) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
    }
  }
}
</script>
