//import axios from "axios";
import axiosInstance from "./api";
import TokenService from "./token.service";

const setupInterceptors = (store) => {

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
  
      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          
          originalConfig._retry = true;
  
          try {
            const rs = await refreshToken();

            //console.log('rs: ' + JSON.stringify(rs));
            store.dispatch('auth/refreshToken', rs);


            //const { rs } = rs.data;
            localStorage.setItem("user", JSON.stringify(rs.data));
            axiosInstance.defaults.headers.common["x-access-token"] = rs.data.token;
  
            return axiosInstance(originalConfig);

          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
  
            return Promise.reject(_error);
          }
        }
  
        if (err.response.status === 403 && err.response.data) {
          return Promise.reject(err.response.data);
        }
      }
  
      return Promise.reject(err);
    }
  );

  function refreshToken() {
    return axiosInstance.post("/token/refresh", {
      refresh_token: TokenService.getLocalRefreshToken()
    });
  }

};

export default setupInterceptors;
