<template>
  <span>
      <span v-if="statut == 'proposed'" class="badge bg-danger">Proposée</span>
      <span v-if="statut == 'validated'" class="badge bg-warning">Validée</span>
      <span v-if="statut == 'progress'" class="badge bg-primary">En-cours</span>
      <span v-if="statut == 'effToCheck'" class="badge bg-secondary">Efficacité à vérifier</span>
      <span v-if="statut == 'effToCheckedOk'" class="badge bg-secondary">Efficacité vérifiée (OK)</span>
      <span v-if="statut == 'effToCheckedNotOk'" class="badge bg-secondary">Efficacité vérifiée (Pas OK)</span>
      <span v-if="statut == 'done'" class="badge bg-success">Réalisée</span>
      <span v-if="statut == 'closed'" class="badge bg-secondary">Clôturée</span>
      <span v-if="statut == 'canceled'" class="badge bg-secondary">Annulée</span>

      <span v-if="type == 'none'" class="badge bg-secondary me-2">N/A</span>
      <span v-if="type == 'curative'" class="badge bg-danger me-2">Curative</span>
      <span v-if="type == 'corrective'" class="badge bg-warning me-2">Corrective</span>
      <span v-if="type == 'preventive'" class="badge bg-success me-2">Préventive</span>
      <span v-if="type == 'improvement'" class="badge bg-success me-2">Amélioration</span>
  </span>
</template>

<script>

export default {
  name: 'ActionStatut',
  props: ['statut', 'type'],
  data: function() {
    return {
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<style>
.header-title {
    color: #153760;
    padding-top: 0.4rem;
    font-weight: bold;
}
h6 {
    font-size: 1.2rem;
    font-weight: bolder;
}
</style>