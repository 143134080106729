<template>
    <div id="remForm">
          <div class="card mb-3">
            <div class="card-body pt-0 pb-0">
  
              <!-- form -->
              <div class="">
                <h6 class="mb-4 text-center text-primary">{{ editing ? 'Terminer' : 'Enregistrer' }} un déplacement</h6>
  
                <FormulateForm @submit="validationForm">
  
                    <div v-if="editing === false" class="text-left mt-4 mb-1"><label>Véhicule</label></div>
                    <Multiselect
                    v-if="editing === false"
                    v-model="deplacement.vehicule" 
                    :options="formSelectVehicules"
                    :searchable="true" 
                    :close-on-select="true" 
                    :show-labels="false" 
                    :custom-label="formSelectVehicules.label"
                    label="label"
                    track-by="value"
                    placeholder="Selectionner le véhicule"
                    :internal-search="false"
                    @search-change="searchChangeVehicule"
                    ></Multiselect>

                    <div class="mb-4"></div>

                    <FormulateInput
                    v-if="editing === false"
                    type="number"
                    v-model="deplacement.kmDebut"
                    label="Km début"
                    placeholder="Indiquer le nombre de km"
                    validation="number"
                    min="0"
                    step="1"
                    />

                    <FormulateInput
                    v-if="editing === true"
                    type="number"
                    v-model="deplacement.kmFin"
                    label="Km fin"
                    placeholder="Indiquer le nombre de km"
                    validation="number"
                    min="0"
                    step="1"
                    />

                  <image-uploader
                    :debug="1"
                    :maxWidth="900"
                    :quality="0.8"
                    :autoRotate=true
                    outputFormat="verbose"
                    :preview=true
                    :className="['fileinput', { 'fileinput--loaded' : deplacement.hasImage }]"
                    :capture="false"
                    accept="image/*"
                    doNotResize="['gif', 'svg']"
                    @input="setImage"
                  ></image-uploader>
                  <!--
                    @onUpload="startImageResize"
                    @onComplete="endImageResize"
                -->
  
                  <div v-if="error" class="alert alert-warning w-100">
                    <i class="fas fa-exclamation-triangle"></i>
                    {{error}}
                  </div>
                  
                  <FormulateInput
                    type="submit"
                    label="Valider"
                    id="buttonDeplacement"
                    input-class="btn btn-success w-100 text-white mt-3"
                  />
                  
                </FormulateForm>
  
              </div>
  
            </div>
          </div>
  
    </div>
  </template>
  
  <script>
  import router from '../router'
  import UserService from '../services/user.service'
  import ImageUploader from 'vue-image-upload-resize'
  import Multiselect from 'vue-multiselect'
  
  var temp = Object.freeze({
    hasImage: false,
    image: null,
    photoBase64: null,
    vehicule: null, 
    kmDebut: null,
    kmFin: null
  });
  
  export default {
    name: 'Form',
    components: {
      ImageUploader,
      Multiselect,
    },
    props: {
      type: {
        type: String,
        default: '',
      },
      isStarted: {
        type: String,
        default: 'false',
      },
    },
    data () {
      return {
        userData: [],
        error: null,
        deplacement: Object.assign({}, temp),
        editing: false,
        formSelectVehicules: [],
      }
    },
    async created() {
      if (this.isStarted == "true") {
        this.editing = true;
      }

      await UserService.getLastDeplacement().then((response) => {
            if (response.data['hydra:member'].length === 1) {
              if (response.data['hydra:member'][0] != null) {
                this.lastDeplacement = response.data['hydra:member'][0];
                this.editing = true;
              }
            }
        },
        error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
        }
        );
    
      this.userData = JSON.parse(localStorage.getItem('userData'));
        await UserService.getVehiculesForm().then((response) => {
            this.formSelectVehicules = response.data['hydra:member'];
            localStorage.setItem('vehiculesForm', JSON.stringify(response.data));
        },
        error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
        }
        );

        this.userVehicules = JSON.parse(localStorage.getItem('vehiculesForm'));
    },
    watch: {
      type(val) {
        if (val == 'add') {
          this.deplacement = Object.assign({}, temp);
        }
      }
    },
    mounted() {
      if (this.isStarted == "true") {
        this.editing = true;
      }
      
    },
    methods: {
        searchChangeVehicule(search) {
            this.formSelectVehicules = search
                ? (this.formSelectVehicules.filter(option => this.normalizedContains(search, option.label)))
                : this.userVehicules['hydra:member'];
        },
      removeDiacritics(text) {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      },
      normalizedContains(needle, haystack) {
        const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
        return regExp.test(this.removeDiacritics(haystack));
      },
      setImage: function(output) {
        this.hasImage = true;
        this.image = output;
      },
      async reload() {
        this.$router.go();
      },
      async validationForm() {
          if(this.isStarted === "true") {
              let deplacementId = this.lastDeplacement.id;
              const deplacement = {
                photoKmFinBase64: this.image != null ? this.image.dataUrl : null,
                updatedBy: "/api/users/" + this.userData.user.id,
                kmFin: parseInt(this.deplacement.kmFin),
              };
              UserService.patchDeplacement(deplacementId, deplacement).then(
                () => {
                  UserService.getUserMe().then(
                    response => {
                      localStorage.setItem('userData', response.data);
                    },
                    error => {
                      this.content = (error.response && error.response.data) || error.message || error.toString();
                    }
                  );
                },
              );
              localStorage.setItem('isStartDeplacement', false);
              document.getElementById('closeModalDeplacement').click();
              router.push('/home');
              this.reload();
              
          } else {
              const deplacement = {
                site: "/api/sites/" + this.userData.user.siteId,
                photoKmDebutBase64: this.image != null ? this.image.dataUrl : null,
                createdBy: "/api/users/" + this.userData.user.id,
                vehicule: "/api/vehicules/" + this.deplacement.vehicule.value,
                kmDebut: parseInt(this.deplacement.kmDebut),
              };
              //console.log(deplacement)
              UserService.postDeplacement(deplacement).then(
                response => {
                  let newDeplacement = response.data;
                  const deplacementId = newDeplacement.id;
                  console.log(newDeplacement, deplacementId)
                  localStorage.setItem('isStartDeplacement', true)
                  document.getElementById('closeModalDeplacement').click();
                  router.push('/deplacements/' + deplacementId);
                },
                error => {
                  this.content = (error.response && error.response.data) || error.message || error.toString();
                }
              
              );
              
              //this.reload();
          }
      },  
    }
  }
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  