<template>
  <div>

    <Header/>

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded"  style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp; &nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/crm">Annuaire</a></li>
          </ol>
        </nav>
      </div>


        
      <div class="container mb-3">
        <div class="card">
          <div class="minimal-tab">
            <ul class="nav nav-tabs p-1" id="affanTab2" role="tablist">
                <li class="nav-item" role="presentation">
                <button class="btn active" id="organisme-tab" data-bs-toggle="tab" data-bs-target="#organisme" type="button" role="tab" aria-controls="organisme" aria-selected="true">Organisme</button>
                </li>
                <li class="nav-item" role="presentation">
                <button class="btn" id="contacts-tab" data-bs-toggle="tab" data-bs-target="#contacts" type="button" role="tab" aria-controls="contacts" aria-selected="false">Contacts</button>
                </li>
            </ul>

            <div class="tab-content rounded-lg" id="affanTab2Content">
                <div class="tab-pane fade show active" id="organisme" role="tabpanel" aria-labelledby="organisme-tab">
                  <div class="container p-0">
                    <div class="card user-info-card chat-user-list rounded-0">
                      <div class="card-body d-flex align-items-center justify-content-center">
                        <div class="user-info text-center">
                          <h6 class="mb-1 text-primary">{{organisme.nom}}</h6>
                        </div>
                      </div>
                    </div>

                    <div class="card rounded-0 rounded-bottom">
                      <ul class="list-group text-left">
                        <li class="list-group-item"><strong>Code : </strong> {{ organisme.code }}</li>
                        <li class="list-group-item"><strong>SIRET : </strong> {{ organisme.siret }}</li>
                        <li v-if="organisme.email" class="list-group-item d-flex align-items-center justify-content-between pe-1">
                          <div>
                            <strong>Email : </strong> {{ organisme.email }}
                          </div>
                          <div>
                            <a v-bind:href="'mailto:' + organisme.email">
                            <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i class="fas fa-envelope"></i></span></a>
                          </div>
                        </li>
                        <li v-if="organisme.telStandard" class="list-group-item d-flex align-items-center justify-content-between pe-1">
                          <div>
                            <strong>Tél. : </strong> {{ organisme.telStandard }}
                          </div>
                          <div>
                            <a v-bind:href="'tel:' + organisme.telStandard">
                              <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i class="fas fa-phone-alt"></i></span></a>
                            <a v-bind:href="'sms:' + organisme.telStandard">
                              <span class="badge bg-primary rounded-pill py-2" style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;">SMS</span></a>
                          </div>
                        </li>
                        <li class="list-group-item d-flex">
                          <div class="col-3"><b>Adresse : </b></div>
                          <div class="col-8 text-left">
                          <span>{{organisme.adresse1}}<br></span>
                          <span v-if="organisme.adresse2">{{organisme.adresse2}}<br></span>
                          <span>{{organisme.cp}} {{organisme.ville}}</span>
                          </div>
                          <div class="col-1 text-right">
                          <a v-if="linkToWaze" :href="linkToWaze">
                            <span class="badge bg-primary rounded-pill py-2"
                              style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;"><i style="font-size: 15px;" class="fab fa-waze"></i></span>
                            </a>
                        </div>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>

                <div class="tab-pane fade" id="contacts" role="tabpanel" aria-labelledby="contacts-tab">
                  <div class="top-products-area" style="background-color: #e2e9fe; border: #e2e9fe;">
                  <div class="container p-0" style="background-color: #e2e9fe; border: #e2e9fe;">
                    <ContactsList v-bind:contacts="contacts" v-bind:listTitle="listTitle" />
                    
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
        

      

    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import ContactsList from '@/components/ContactsList.vue'
import UserService from '../services/user.service';

export default {
  name: 'Organisme',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      organisme: [],
      contacts: [],
      listTitle: 'Recherche en cours',
      linkToWaze: null,
    }
  },
  components: {
    Header,
    Footer,
    ContactsList,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    if(this.loggedIn) {
      // organisme
      await UserService.getOrganisme(this.$route.params.id).then((response) => {
          this.organisme = response.data;
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

      if (this.organisme.adresse1) {
        let formatedAdresse = this.organisme.adresse1.replaceAll(' ', '%20');
        let formatedVille = this.organisme.ville.replaceAll(' ', '%20');
        this.linkToWaze = 'https://waze.com/ul?q=' + formatedAdresse + '%20' + formatedVille;
      }

      // contacts
      await UserService.getContactsByOrganisme(this.$route.params.id).then((response) => {
        this.contacts = response.data['hydra:member'];
        let total = response.data['hydra:totalItems'];
        if (total > 1) {
          this.listTitle = '';
        }
        if (total === 0) {
          this.listTitle = 'Aucun contact associé !';
        }
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
  },
  mounted() {
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 80px;
    vertical-align: middle;
    -o-object-fit: cover;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}
</style>
