<template>
  <div>

    <Header/>

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded"  style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp; &nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/vente">Vente</a></li>

          </ol>
        </nav>
      </div>


        
      <div class="container mb-3">
        <div class="card">
          <div class="minimal-tab">
            <ul class="nav nav-tabs p-1" id="affanTab2" role="tablist">
                <li class="nav-item" role="presentation">
                <button class="btn active" id="sass-tab" data-bs-toggle="tab" data-bs-target="#sass" type="button" role="tab" aria-controls="sass" aria-selected="true">Prospects particuliers</button>
                </li>
            </ul>

            <div class="tab-content rounded-lg" id="affanTab2Content">
                <div class="tab-pane fade show active" id="sass" role="tabpanel" aria-labelledby="sass-tab">

                  <div class="container mt-3">
                    <form class="mb-3 border-bottom" @submit.prevent="submitSearch">
                      <div class="input-group">
                        <input class="form-control form-control-clicked" @input="restartSearch" type="search" name="search" v-model="search" placeholder="Recherche">
                        <button v-if="!isSearch" class="btn btn-primary text-white" type="submit" style="width:3rem; padding-top: 0.5rem;"><i class="fas fa-search fz-14"></i></button>
                        <button v-if="isSearch" @click.prevent="emptySearch" class="btn btn-primary text-white" style="width:3rem;padding-top: 0.5rem;"><i class="fas fa-times fz-14"></i></button>            
                      </div>
                    </form>

                    <div v-if="isSearch" class="alert rounded mt-3" v-bind:class="total > 0 ? 'bg-success' : 'bg-danger'">
                      <h6 class="mb-0 line-height-1 text-white">{{ listTitle }}</h6>
                    </div>
                  </div>

                  <ul v-if="total > 0 && isSearch" class="ps-0 chat-user-list rounded-0 w-100">
                    <li v-for="(organisme, index) in organismes" :key="organisme.id" class="p-3 chat-unread" v-bind:class="{ 'rounded-bottom': index === (organismes.length-1) }">
                        <a class="d-flex" v-bind:href="'/organismes/' + organisme.id">
                          <div class="chat-user-info text-left">
                            <h6 class="text-truncate mb-0">{{ organisme.nom }}</h6>
                            <div class="last-chat">
                              <p class="mb-1">
                                <span class="" v-if="organisme.activite">{{ organisme.activite }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="organisme.email">{{ organisme.email }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="organisme.telStandard">{{ organisme.telStandard }}</span>
                              </p>
                              <p v-if="organisme.isActif === false" class="mb-1">
                                <span class="badge bg-danger">Inactif</span> 
                              </p>
                            </div>
                          </div>
                        </a>
                        <!-- Options -->
                        <div class="dropstart chat-options-btn">
                          <a v-bind:href="'/organismes/' + organisme.id" class="">
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </li>
                    </ul>   
                </div>
            </div>
          </div>
        </div>
      </div>
        

      

    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'ProspectParticulier',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      organismes: [],
      listTitle: "Recherche en cours",
      search: null,
      isSearch: false,
      total: 0,
    }
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }
  },
  mounted() {
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    submitSearch: async function () {
      if(this.search.length > 0) {
        this.search = this.search.trim();
        this.isSearch = true;
        this.listTitle = 'Recherche en cours'
        await UserService.getProspectSearch(this.search, 'particulier').then(
          response => {
            this.total = response.data['hydra:member'].length;
            this.listTitle = this.total + ' prospect trouvé pour "' + this.search + '"';
            if(this.total > 1) {
              this.listTitle = this.total + ' prospects trouvés pour "' + this.search + '"';
            }
            this.organismes = response.data['hydra:member'];
            console.log(response.data['hydra:member'])
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
    },
    restartSearch: function () {
      this.isSearch = false;
    },
    emptySearch: function () {
      this.search = null;
      this.isSearch = false;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 80px;
    vertical-align: middle;
    -o-object-fit: cover;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}
</style>
