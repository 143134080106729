import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Entreprise from '../views/Entreprise.vue'
import Crm from '../views/Crm.vue'
import Vente from '../views/Vente.vue'
import Spanco from '../views/Spanco.vue'
import Vehicule from '../views/Vehicule.vue'
import Vehicules from '../views/Vehicules.vue'
import Client from '../views/Client.vue'
import Clients from '../views/Clients.vue'
import Organisme from '../views/Organisme.vue'
import Contact from '../views/Contact.vue'
import DeplacementValidationForm from '../views/DeplacementValidationForm.vue'
import Memo from '../views/Memo.vue'
import Projet from '../views/Projet.vue'
import Action from '../views/Action.vue'
import Deplacement from '../views/Deplacement.vue'
import Collaborateur from '../views/Collaborateur.vue'
import ProspectEntreprises from '../views/ProspectEntreprises.vue'
import ProspectParticuliers from '../views/ProspectParticuliers.vue'

Vue.use(VueRouter)

const routes = [
  // HOME
  {
    path: '/',
    alias: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },

  // entreprise
  {
    path: '/entreprise',
    name: 'entreprise',
    component: Entreprise
  },

  {
    path: '/projets/:id',
    name: 'projet',
    component: Projet
  },

  {
    path: '/actions/:id',
    name: 'action',
    component: Action
  },

  // CRM
  {
    path: '/crm',
    name: 'crm',
    component: Crm
  },
  {
    path: '/clients',
    name: 'clients',
    component: Clients
  },
  {
    path: '/clients/:id',
    name: 'client',
    component: Client
  },
  {
    path: '/organismes/:id',
    name: 'organisme',
    component: Organisme
  },
  {
    path: '/organismes/prospects/entreprises',
    name: 'prospectEntreprise',
    component: ProspectEntreprises
  },
  {
    path: '/organismes/prospects/particuliers',
    name: 'prospectParticulier',
    component: ProspectParticuliers
  },
  {
    path: '/contacts/:id',
    name: 'contact',
    component: Contact
  },
  {
    path: '/collaborateurs/:id',
    name: 'collaborateur',
    component: Collaborateur
  },

  // VENTE
  {
    path: '/vente',
    name: 'vente',
    component: Vente
  },
  {
    path: '/spanco',
    name: 'spanco',
    component: Spanco
  },
  {
    path: '/memos/:id',
    name: 'memo',
    component: Memo
  },

  // VEHICULE
  {
    path: '/vehicules',
    name: 'vehicules',
    component: Vehicules
  },
  {
    path: '/vehicules/:id',
    name: 'vehicule',
    component: Vehicule
  },
  {
    path: '/deplacements/:id',
    name: 'deplacement',
    component: Deplacement
  },
  {
    path: '/deplacements/:id/form',
    name: 'deplacementForm',
    component: DeplacementValidationForm
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router