<template>
  <div>

    <Header/>

    <div class="page-content-wrapper">

      <div class="pt-3">

        <div class="row">
            <div class="col-6 ps-4">
                <div class="card">
                  <div class="card-body">
                    <a href="/entreprise">
                      <div class="feature-card mx-auto text-center">
                        <div class="card mx-auto bg-primary"><i class="fas fa-briefcase" style="font-size: 30px; color: white;"></i></div>
                        <p class="mb-0 text-primary" style="font-size: 16px;"><b>Mon compte</b></p>
                      </div>
                    </a>
                  </div>
                </div>
            </div>
            <div class="col-6 pe-4">
              <div class="card">
                <div class="card-body">
                  <a href="/crm">
                    <div class="feature-card mx-auto text-center">
                      <div class="card mx-auto bg-primary"><i class="fas fa-users" style="font-size: 30px; color: white;"></i></div>
                      <p class="mb-0 text-primary" style="font-size: 16px;"><b>Annuaire</b></p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-6 ps-4">
              <div class="card">
                <div class="card-body">
                  <a href="/vente">
                    <div class="feature-card mx-auto text-center">
                      <div class="card mx-auto bg-primary"><i class="fas fa-euro-sign" style="font-size: 30px; color: white;"></i></div>
                      <p class="mb-0 text-primary" style="font-size: 16px;"><b>Ventes</b></p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-6 pe-4">
              <div class="card">
                <div class="card-body">
                  <a href="/vehicules">
                    <div class="feature-card mx-auto text-center">
                      <div class="card mx-auto bg-primary"><i class="fas fa-car" style="font-size: 30px; color: white;"></i></div>
                      <p class="mb-0 text-primary" style="font-size: 16px;"><b>Véhicules</b></p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
        </div>

        <div class="row mt-4">
          <div class="col-12 ps-4 pe-4">
            <div class="card">
                <div class="card-body">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#modalDeplacement">
                    <div class="feature-card mx-auto text-center">
                      <div class="card mx-auto" v-bind:class="isStartDeplacement === 'true' ? 'bg-warning' : 'bg-success'"><i class="fas fa-map-signs" style="font-size: 30px; color: white;"></i></div>
                      <p v-if="isStartDeplacement === 'true'" class="mb-0 text-warning" style="font-size: 16px;"><b>Terminer le déplacement</b></p>
                      <p v-else class="mb-0 text-success" style="font-size: 16px;"><b>Nouveau déplacement</b></p>
                    </div>
                  </a>
                </div>
              </div>
          </div>
        </div>

        <div class="modal fade" id="modalDeplacement" tabindex="-1" aria-labelledby="modalDeplacementLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <button id="closeModalDeplacement" class="btn btn-close p-1 ms-auto" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <DeplacementForm :type="formType" :isStarted="isStartDeplacement" />
              </div>
              <div class="modal-footer">
                <button class="btn btn-sm btn-secondary text-white" type="button" data-bs-dismiss="modal">Annuler</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="modalDeplacementValidation" tabindex="-1" aria-labelledby="modalDeplacementValidationLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header">
                <button id="closeModalDeplacementValidation" class="btn btn-close p-1 ms-auto" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <DeplacementValidationForm :type="formType" :isStarted="isStartDeplacement" />
              </div>
              <div class="modal-footer">
                <button class="btn btn-sm btn-secondary text-white" type="button" data-bs-dismiss="modal">Annuler</button>
              </div>
            </div>
          </div>
        </div>

        


      </div>

      <div class="pb-3"></div>

    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import DeplacementForm from '@/components/DeplacementForm.vue'
import DeplacementValidationForm from '@/components/DeplacementValidationForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'Home',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      date: null,
      editing: false,
      formType: '',
      isNewVersion: false,
      newVersion: null,
      isStartDeplacement: 'false',
    }
  },
  components: {
    Header,
    Footer,
    DeplacementForm,
    DeplacementValidationForm,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    if(this.loggedIn) {
      // last version
      await UserService.getLastVersion().then((response) => {
          let lastVersion = JSON.parse(response.data);
          if (lastVersion.length > 0 && lastVersion.code.slice(2) != this.$version.slice(2)) {
            this.isNewVersion = true;
            this.newVersion = lastVersion;
          }
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }

    // userData
    let userData = JSON.parse(localStorage.getItem('userData'));
    let user = userData.user;
    let siteId = user.siteId;
    await UserService.getUserMe(siteId).then(
      response => {
        localStorage.setItem('userData', response.data);
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );

    this.isStartDeplacement = localStorage.getItem('isStartDeplacement');
  },
  mounted() {
    this.isStartDeplacement = localStorage.getItem('isStartDeplacement');
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    activateNewVersion() {
      const staticCacheName = 'cache-v' + this.$version;
      const dynamicCacheName = 'runtimeCache-v' + this.$version;
      caches.delete(staticCacheName).then(function() {});
      caches.delete(dynamicCacheName).then(function() {});
      this.$router.go();
    }
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 80px;
    vertical-align: middle;
    -o-object-fit: cover;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}
</style>
