<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp;
                  &nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/vehicules">Véhicules</a></li>
          </ol>
        </nav>
      </div>



      <div class="container mb-3">
        <div class="card">


          <div class="container mt-3">
            <form class="mb-3 border-bottom" @submit.prevent="submitSearch">
              <div class="input-group">
                <input class="form-control form-control-clicked" @input="restartSearch" type="search" name="search"
                  v-model="search" placeholder="Rechercher un véhicule ...">
                <button v-if="!isSearch" class="btn btn-primary text-white" type="submit"
                  style="width:3rem; padding-top: 0.5rem;"><i class="fas fa-search fz-14"></i></button>
                <button v-if="isSearch" @click.prevent="emptySearch" class="btn btn-primary text-white"
                  style="width:3rem;padding-top: 0.5rem;"><i class="fas fa-times fz-14"></i></button>
              </div>
            </form>

            <div v-if="isSearch" class="alert rounded mt-3"
              v-bind:class="vehicules.length > 0 ? 'bg-primary' : 'bg-danger'">
              <h6 class="mb-0 line-height-1 text-white">{{ listTitle }}</h6>
            </div>
          </div>

          <ul v-if="vehicules.length > 0" class="ps-0 chat-user-list rounded-0">

            <li v-for="(vehicule, index) in vehicules" :key="vehicule.id" class="p-3 chat-unread"
              v-bind:class="{ 'rounded-bottom': index === (vehicules.length - 1) }">
              <a class="d-flex" v-bind:href="'/vehicules/' + vehicule.id">
                <!-- Thumbnail
                <div class="chat-user-thumbnail me-3 shadow">
                  <img v-if="client.logo" :src="$urlUploads + client.logo" alt="logo" class="img-circle">
                  <img v-else src="img/no-image.png" alt="logo" class="img-circle">
                </div>
                 -->
                <!-- Info -->
                <div class="chat-user-info text-left">
                  <h6 class="text-truncate mb-0">{{ vehicule.code }} - {{ vehicule.modele }} ({{ vehicule.marque }})</h6>
                </div>
              </a>
              <!-- Options -->
              <div class="dropstart chat-options-btn">
                <a v-bind:href="'/vehicules/' + vehicule.id" class="">
                  <i class="fas fa-chevron-right"></i>
                </a>
              </div>
            </li>



          </ul>
          <div v-else-if="vehicules.length == 0 && !isSearch" class="card bg-danger rounded-0 rounded-bottom">
            <div class="card-body text-center py-3">
              <h6 class="mb-0 text-white line-height-1">{{ listTitle }}</h6>
            </div>
          </div>
        </div>
      </div>




    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'Vehicules',
  data: function () {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      vehicules: [],
      listTitle: "Recherche en cours",
      search: null,
      isSearch: false,
    }
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    if (this.loggedIn) {
      // vehicules
      await UserService.getVehicules().then((response) => {
        this.vehicules = response.data['hydra:member'];
        localStorage.setItem('vehicules', JSON.stringify(response.data));
        let total = response.data['hydra:totalItems'];
        if (total > 1) {
          this.listTitle = '';
        }
        if (total === 0) {
          this.listTitle = 'Aucun véhicule !';
        }
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
  },
  mounted() {

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    submitSearch: async function () {
      if (this.search.length > 0) {
        this.search = this.search.trim();
        this.isSearch = true;
        this.listTitle = 'Recherche en cours'
        await UserService.getVehiculesSearch(this.search).then(
          response => {
            let total = response.data['hydra:totalItems'];
            this.listTitle = total + ' vehicule trouvé pour "' + this.search + '"';
            if (total > 1) {
              this.listTitle = total + ' vehicules trouvés pour "' + this.search + '"';
            }
            localStorage.setItem('vehiculesSearch', JSON.stringify(response.data));
            this.vehicules = response.data['hydra:member'];
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
    },
    restartSearch: function () {
      this.isSearch = false;
    },
    emptySearch: function () {
      this.search = null;
      this.isSearch = false;
      let listVehicules = JSON.parse(localStorage.getItem('vehicules'));
      if (listVehicules) { this.vehicules = listVehicules['hydra:member']; }
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

  },
  watch: {
    '$route.query.type': {
      handler: function (type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}

.avatar-img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 80px;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}</style>
