<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp;
                  &nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/crm">Annuaire</a></li>
          </ol>
        </nav>
      </div>



      <div class="container mb-3">
        <div class="card user-info-card chat-user-list rounded-0">
          <div class="card-body d-flex align-items-center justify-content-center">
            <div class="user-info text-center">
              <h6 class="mb-1 text-primary">{{ collaborateur.nom }} {{ collaborateur.prenom }}</h6>
              <p v-if="collaborateur.fonction">{{ collaborateur.fonction }}</p>
              <span v-if="collaborateur.isActif === false" class="badge bg-danger">Inactif</span> 
            </div>
          </div>
        </div>

        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group text-left">
            <li v-if="collaborateur.emailPro" class="list-group-item d-flex align-items-center justify-content-between pe-1">
              <div>
                <strong>Email pro : </strong> {{ collaborateur.emailPro }}
              </div>
              <div>
                <a v-bind:href="'mailto:' + collaborateur.emailPro">
                  <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                      class="fas fa-envelope"></i></span></a>
              </div>
            </li>
            <li v-if="collaborateur.emailPerso" class="list-group-item d-flex align-items-center justify-content-between pe-1">
              <div>
                <strong>Email perso : </strong> {{ collaborateur.emailPerso }}
              </div>
              <div>
                <a v-bind:href="'mailto:' + collaborateur.emailPerso">
                  <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                      class="fas fa-envelope"></i></span></a>
              </div>
            </li>
            <li v-if="collaborateur.telBureau"
              class="list-group-item d-flex align-items-center justify-content-between pe-1">
              <div>
                <strong>Tél. bureau : </strong> {{ collaborateur.telBureau }}
              </div>
              <div>
                <a v-bind:href="'tel:' + collaborateur.telBureau">
                  <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                      class="fas fa-phone-alt"></i></span></a>
                <a v-bind:href="'sms:' + collaborateur.telBureau">
                  <span class="badge bg-primary rounded-pill py-2"
                    style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;">SMS</span></a>
              </div>
            </li>
            <li v-if="collaborateur.telPoste"
              class="list-group-item d-flex align-items-center justify-content-between pe-1">
              <div>
                <strong>Tél. poste : </strong> {{ collaborateur.telPoste }}
              </div>
              <div>
                <a v-bind:href="'tel:' + collaborateur.telPoste">
                  <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                      class="fas fa-phone-alt"></i></span></a>
                <a v-bind:href="'sms:' + collaborateur.telPoste">
                  <span class="badge bg-primary rounded-pill py-2"
                    style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;">SMS</span></a>
              </div>
            </li>
            <li v-if="collaborateur.telMobilePro"
              class="list-group-item d-flex align-items-center justify-content-between pe-1">
              <div>
                <strong>Tél. pro: </strong> {{ collaborateur.telMobilePro }}
              </div>
              <div>
                <a v-bind:href="'tel:' + collaborateur.telMobilePro">
                  <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                      class="fas fa-phone-alt"></i></span></a>
                <a v-bind:href="'sms:' + collaborateur.telMobilePro">
                  <span class="badge bg-primary rounded-pill py-2"
                    style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;">SMS</span></a>
              </div>
            </li>
            <li v-if="collaborateur.telMobilePerso"
              class="list-group-item d-flex align-items-center justify-content-between pe-1">
              <div>
                <strong>Tél. perso: </strong> {{ collaborateur.telMobilePerso }}
              </div>
              <div>
                <a v-bind:href="'tel:' + collaborateur.telMobilePerso">
                  <span class="badge bg-primary rounded-pill py-2" style="padding: 0 0.5rem;width: 45px;"><i
                      class="fas fa-phone-alt"></i></span></a>
                <a v-bind:href="'sms:' + collaborateur.telMobilePerso">
                  <span class="badge bg-primary rounded-pill py-2"
                    style="margin-left: 0.5rem; padding: 0 0.5rem;width: 45px;">SMS</span></a>
              </div>
            </li>
          </ul>
        </div>

      </div>




    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'Collaborateur',
  data: function () {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      collaborateur: [],
    }
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    if (this.loggedIn) {
      await UserService.getCollaborateur(this.$route.params.id).then((response) => {
        this.collaborateur = response.data;
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
  },
  mounted() {

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

  },
  watch: {
    '$route.query.type': {
      handler: function (type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}

.avatar-img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 80px;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}</style>
