<template>
  <span>
      <span v-if="statut == 'proposed'" class="badge bg-danger">Proposé</span>
      <span v-if="statut == 'validated'" class="badge bg-warning">Validé</span>
      <span v-if="statut == 'progress'" class="badge bg-primary">En-cours</span>
       <span v-if="statut == 'done'" class="badge bg-success">Réalisé</span>
      <span v-if="statut == 'closed'" class="badge bg-secondary">Clôturé</span>
      <span v-if="statut == 'canceled'" class="badge bg-secondary">Annulé</span>
  </span>
</template>

<script>

export default {
  name: 'ProjetStatut',
  props: ['statut'],
  data: function() {
    return {
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<style>
.header-title {
    color: #153760;
    padding-top: 0.4rem;
    font-weight: bold;
}
h6 {
    font-size: 1.2rem;
    font-weight: bolder;
}
</style>