<template>
  <div>

    <Header/>

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp;&nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/entreprise">Mon compte</a></li>
          </ol>
        </nav>
      </div>

      <div class="pt-3">

        <div class="container mb-3">
          <div class="card bg-primary rounded-0 rounded-top">
            <div class="py-1 text-center">
              <h6 class="pt-2 text-white text-center">Mon compte</h6>
            </div>
          </div>
          <div class="card rounded-0 rounded-bottom">
            <ul class="list-group">

              <li class="list-group-item d-flex justify-content-between"><span><b>Actions (pilote)</b></span>
                  <a :href="'#collapse-actions-pilote'" class="ml-2 text-emb" data-bs-toggle="collapse" aria-expanded="false" aria-controls="">
                    <span class="badge " v-bind:class="actionsPilote.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ actionsPilote.length }}</span>
                    <i class="fas fa-chevron-down fas-modify ms-3"></i>
                  </a>
                </li>
                <li :id="'collapse-actions-pilote'" class="collapse list-group-item p-1">
                  <div v-if="actionsPilote.length > 0">
                  <ul class="ps-0 chat-user-list rounded-0">
                    <div v-for="(actionPilote) in actionsPilote" :key="actionPilote.id">
                      <li class="p-3 chat-unread">
                        <div class="chat-user-info text-left">
                          <h6 class="text-truncate mb-0">{{ actionPilote.code }} - {{ actionPilote.nom }}</h6>
                          <div class="last-chat w-100">
                            <span class="badge bg-warning me-2">{{ actionPilote.echeance|formatDate }}</span>
                            <span class="badge bg-primary">{{ actionPilote.demandeur.prenom }} {{ actionPilote.demandeur.nom }}</span>
                          </div>
                        </div>
                        <div class="dropstart chat-options-btn">
                          <a :href="'/actions/' + actionPilote.id" style="float: right;" class="mb-4 text-primary"><i class="fas fa-chevron-right"></i></a>   
                        </div>
                      </li>
                    </div>
                  </ul>
                  </div>
                  <div v-else class="alert alert-warning w-100">Aucune action en tant que pilote !</div>
                </li>
              
                
                <li class="list-group-item d-flex justify-content-between"><span><b>Projets (pilote)</b></span>
                  <a :href="'#collapse-projets-pilote'" class="ml-2 text-emb" data-bs-toggle="collapse" aria-expanded="false" aria-controls="">
                    <span class="badge " v-bind:class= "projetsPilote.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ projetsPilote.length }}</span>
                    <i class="fas fa-chevron-down fas-modify ms-3"></i>
                  </a>
                </li>
                <li :id="'collapse-projets-pilote'" class="collapse list-group-item p-1">
                  <div v-if="projetsPilote.length > 0">
                  <ul class="ps-0 chat-user-list rounded-0">
                    <div v-for="(projetPilote) in projetsPilote" :key="projetPilote.id">
                      <li class="p-3 chat-unread">
                        <div class="chat-user-info text-left">
                          <h6 class="text-truncate mb-0">{{ projetPilote.code }} - {{ projetPilote.nom }}</h6>
                          <div class="last-chat w-100">
                            <span class="badge bg-warning me-2">{{ projetPilote.echeance|formatDate }}</span>
                            <span class="badge bg-primary">{{ projetPilote.demandeur.prenom }} {{ projetPilote.demandeur.nom }}</span>
                          </div>
                        </div>
                        <div class="dropstart chat-options-btn">
                          <a :href="'/projets/' + projetPilote.id" style="float: right;" class="mb-4 text-primary"><i class="fas fa-chevron-right"></i></a>   
                        </div>
                      </li>
                    </div>
                  </ul>
                  </div>
                  <div v-else class="alert alert-warning w-100">Aucun projet en tant que pilote !</div>
                </li>

                <li class="list-group-item d-flex justify-content-between"><span><b>Projets (demandeur)</b></span>
                  <a :href="'#collapse-projets-demandeur'" class="ml-2 text-emb" data-bs-toggle="collapse" aria-expanded="false" aria-controls="">
                    <span class="badge " v-bind:class="projetsDemandeur.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ projetsDemandeur.length }}</span>
                    <i class="fas fa-chevron-down fas-modify ms-3"></i>
                  </a>
                </li>
                <li :id="'collapse-projets-demandeur'" class="collapse list-group-item p-1">
                  <div v-if="projetsDemandeur.length > 0">
                  <ul class="ps-0 chat-user-list rounded-0">
                    <div v-for="(projetDemandeur) in projetsDemandeur" :key="projetDemandeur.id">
                      <li class="p-3 chat-unread">
                        <div class="chat-user-info text-left">
                          <h6 class="text-truncate mb-0">{{ projetDemandeur.code }} - {{ projetDemandeur.nom }}</h6>
                          <div class="last-chat w-100">
                            <span class="badge bg-warning me-2">{{ projetDemandeur.echeance|formatDate }}</span>
                            <span class="badge bg-primary">{{ projetDemandeur.pilote.prenom }} {{ projetDemandeur.pilote.nom }}</span>
                          </div>
                        </div>
                        <div class="dropstart chat-options-btn">
                          <a :href="'/projets/' + projetDemandeur.id" style="float: right;" class="mb-4 text-primary"><i class="fas fa-chevron-right"></i></a>   
                        </div>
                      </li>
                    </div>
                  </ul>
                  </div>
                  <div v-else class="alert alert-warning w-100">Aucun projet en tant que demandeur !</div>
                </li>


                <li class="list-group-item d-flex justify-content-between"><span><b>Actions (demandeur)</b></span>
                  <a :href="'#collapse-actions-demandeur'" class="ml-2 text-emb" data-bs-toggle="collapse" aria-expanded="false" aria-controls="">
                    <span class="badge " v-bind:class="actionsDemandeur.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ actionsDemandeur.length }}</span>
                    <i class="fas fa-chevron-down fas-modify ms-3"></i>
                  </a>
                </li>
                <li :id="'collapse-actions-demandeur'" class="collapse list-group-item p-1">
                  <div v-if="actionsDemandeur.length > 0">
                  <ul class="ps-0 chat-user-list rounded-0">
                    <div v-for="(actionDemandeur) in actionsDemandeur" :key="actionDemandeur.id">
                      <li class="p-3 chat-unread">
                        <div class="chat-user-info text-left">
                          <h6 class="text-truncate mb-0">{{ actionDemandeur.code }} - {{ actionDemandeur.nom }}</h6>
                          <div class="last-chat w-100">
                            <span class="badge bg-warning me-2">{{ actionDemandeur.echeance|formatDate }}</span>
                            <span class="badge bg-primary">{{ actionDemandeur.pilote.prenom }} {{ actionDemandeur.pilote.nom }}</span>
                          </div>
                        </div>
                        <div class="dropstart chat-options-btn">
                          <a :href="'/actions/' + actionDemandeur.id" style="float: right;" class="mb-4 text-primary"><i class="fas fa-chevron-right"></i></a>   
                        </div>
                      </li>
                    </div>
                  </ul>
                  </div>
                  <div v-else class="alert alert-warning w-100">Aucune action en tant que demandeur !</div>
                </li>

                

            </ul>
          </div>
        </div>
        

        


      </div>

      <div class="pb-3"></div>

    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'Entreprise',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      projetsDemandeur: [],
      projetsPilote: [],
      actionsDemandeur: [],
      actionsPilote: [],
    }
  },
  components: {
    Header,
    Footer,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    // projets > demandeur
    await UserService.getProjets('demandeur').then((response) => {
      this.projetsDemandeur = response.data['hydra:member'];
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );

    // projets > pilote
    await UserService.getProjets('pilote').then((response) => {
      this.projetsPilote = response.data['hydra:member'];
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );

    // actions > demandeur
    await UserService.getActions('demandeur').then((response) => {
      this.actionsDemandeur = response.data['hydra:member'];
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );

    // actions > pilote
    await UserService.getActions('pilote').then((response) => {
      this.actionsPilote = response.data['hydra:member'];
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
  },
  mounted() {
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 80px;
    vertical-align: middle;
    -o-object-fit: cover;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}
</style>
