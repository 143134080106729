<template>
  <div>

    <Header/>

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded"  style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp; &nbsp;</span> Accueil</a></li>
          </ol>
        </nav>
      </div>


        
      <div class="container mb-3">
        <div class="card">
          <div class="minimal-tab">
            <ul class="nav nav-tabs p-1" id="affanTab2" role="tablist">
                <li class="nav-item" role="presentation">
                <button class="btn active" id="sass-tab" data-bs-toggle="tab" data-bs-target="#sass" type="button" role="tab" aria-controls="sass" aria-selected="true">Annuaire</button>
                </li>
            </ul>

            <div class="tab-content rounded-lg" id="affanTab2Content">
                <div class="tab-pane fade show active" id="sass" role="tabpanel" aria-labelledby="sass-tab">

                  <div class="container mt-3">
                    <form class="mb-3 border-bottom" @submit.prevent="submitSearch">
                      <div class="input-group">
                        <input class="form-control form-control-clicked" @input="restartSearch" type="search" name="search" v-model="search" placeholder="Recherche">
                        <button v-if="!isSearch" class="btn btn-primary text-white" type="submit" style="width:3rem; padding-top: 0.5rem;"><i class="fas fa-search fz-14"></i></button>
                        <button v-if="isSearch" @click.prevent="emptySearch" class="btn btn-primary text-white" style="width:3rem;padding-top: 0.5rem;"><i class="fas fa-times fz-14"></i></button>            
                      </div>
                    </form>

                    <div v-if="isSearch" class="alert rounded mt-3" v-bind:class="total > 0 ? 'bg-success' : 'bg-danger'">
                      <h6 class="mb-0 line-height-1 text-white">{{ listTitle }}</h6>
                    </div>
                  </div>

                  <ul v-if="total > 0 && isSearch" class="ps-0 chat-user-list rounded-0">

                    <li v-if="clients.length > 0" class="list-group-item rounded-0">
                      <a :href="'#collapse-clients'" class="ml-2 d-flex justify-content-between w-100" data-bs-toggle="collapse" aria-expanded="false" aria-controls="">
                        <h6 class="text-primary">Clients</h6>
                        <div>
                        <span class="badge " v-bind:class="clients.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ clients.length }}</span>
                        <i class="fas fa-chevron-down fa-modify ms-3"></i>
                        </div>
                      </a>
                    </li>
                    <li :id="'collapse-clients'" class="collapse">
                      <ul class="ps-0 chat-user-list rounded-0 w-100">
                      <li v-for="(client, index) in clients" :key="client.id" class="p-3 chat-unread" v-bind:class="{ 'rounded-bottom': index === (clients.length-1) }">
                        <a class="d-flex" v-bind:href="'/clients/' + client.id">
                          <!-- Thumbnail -->
                          <div class="chat-user-thumbnail me-3 shadow">
                            <img v-if="client.logo" :src="$urlUploads + client.logo" alt="logo" class="img-circle">
                            <img v-else src="img/no-image.png" alt="logo" class="img-circle">
                          </div>
                          <!-- Info -->
                          <div class="chat-user-info text-left">
                            <h6 class="text-truncate mb-0">{{ client.nom }}</h6>
                            <div class="last-chat">
                              <p class="mb-1">
                                <a v-if="client.particulierEmail" v-bind:href="'mailto:' + client.particulierEmail">
                                  <span class="badge bg-success">{{client.particulierEmail}}</span> 
                                </a>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="client.particulierTel">{{client.particulierTel}}</span> 
                              </p>
                              <p v-if="client.isActif === false" class="mb-1">
                                <span class="badge bg-danger">Inactif</span> 
                              </p>
                            </div>
                          </div></a>
                        <!-- Options -->
                        <div class="dropstart chat-options-btn">
                          <a v-bind:href="'/clients/' + client.id" class="">
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </li>
                      </ul>
                    </li>



                    <li v-if="organismes.length > 0" class="list-group-item rounded-0">
                      <a :href="'#collapse-organismes'" class="ml-2 d-flex justify-content-between w-100" data-bs-toggle="collapse" aria-expanded="false" aria-controls="">
                        <h6 class="text-primary">Organismes</h6>
                        <div>
                        <span class="badge " v-bind:class="organismes.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ organismes.length }}</span>
                        <i class="fas fa-chevron-down fa-modify ms-3"></i>
                        </div>
                      </a>
                    </li>
                    <li :id="'collapse-organismes'" class="collapse">
                      <ul class="ps-0 chat-user-list rounded-0 w-100">
                      <li v-for="(organisme, index) in organismes" :key="organisme.id" class="p-3 chat-unread" v-bind:class="{ 'rounded-bottom': index === (organismes.length-1) }">
                        <a class="d-flex" v-bind:href="'/organismes/' + organisme.id">
                          <div class="chat-user-info text-left">
                            <h6 class="text-truncate mb-0">{{ organisme.nom }}</h6>
                            <div class="last-chat">
                              <p class="mb-1">
                                <span class="" v-if="organisme.activite">{{ organisme.activite }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="organisme.email">{{ organisme.email }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="organisme.telStandard">{{ organisme.telStandard }}</span>
                              </p>
                              <p v-if="organisme.isActif === false" class="mb-1">
                                <span class="badge bg-danger">Inactif</span> 
                              </p>
                            </div>
                          </div>
                        </a>
                        <!-- Options -->
                        <div class="dropstart chat-options-btn">
                          <a v-bind:href="'/organismes/' + organisme.id" class="">
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </li>
                      </ul>
                    </li>



                    <li v-if="contacts.length > 0" class="list-group-item rounded-0">
                      <a :href="'#collapse-contacts'" class="ml-2 d-flex justify-content-between w-100" data-bs-toggle="collapse" aria-expanded="false" aria-controls="">
                        <h6 class="text-primary">Contacts</h6>
                        <div>
                        <span class="badge " v-bind:class="contacts.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ contacts.length }}</span>
                        <i class="fas fa-chevron-down fa-modify ms-3"></i>
                        </div>
                      </a>
                    </li>
                    <li :id="'collapse-contacts'" class="collapse">
                      <ul class="ps-0 chat-user-list rounded-0 w-100">
                      <li v-for="(contact, index) in contacts" :key="contact.id" class="p-3 chat-unread" v-bind:class="{ 'rounded-bottom': index === (contacts.length-1) }">
                        <a class="d-flex" v-bind:href="'/contacts/' + contact.id">
                          <div class="chat-user-info text-left">
                            <h6 class="text-truncate mb-0">{{ contact.prenom }} {{ contact.nom }}</h6>
                            <div class="last-chat">
                              <p class="mb-1">
                                <span class="" v-if="contact.fonction">{{ contact.fonction }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="contact.email">{{ contact.email }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="contact.telMobile">{{ contact.telMobile }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="contact.telBureau">{{ contact.telBureau }}</span>
                              </p>
                              <p v-if="contact.isActif === false" class="mb-1">
                                <span class="badge bg-danger">Inactif</span> 
                              </p>
                            </div>
                          </div>
                        </a>
                        <!-- Options -->
                        <div class="dropstart chat-options-btn">
                          <a v-bind:href="'/contacts/' + contact.id" class="">
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </li>
                      </ul>
                    </li>


                    <li v-if="collaborateurs.length > 0" class="list-group-item rounded-0">
                      <a :href="'#collapse-collaborateurs'" class="ml-2 d-flex justify-content-between w-100" data-bs-toggle="collapse" aria-expanded="false" aria-controls="">
                        <h6 class="text-primary">Collaborateurs</h6>
                        <div>
                        <span class="badge " v-bind:class="collaborateurs.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ collaborateurs.length }}</span>
                        <i class="fas fa-chevron-down fa-modify ms-3"></i>
                        </div>
                      </a>
                    </li>
                    <li :id="'collapse-collaborateurs'" class="collapse">
                      <ul class="ps-0 chat-user-list rounded-0 w-100">
                      <li v-for="(collaborateur, index) in collaborateurs" :key="collaborateur.id" class="p-3 chat-unread" v-bind:class="{ 'rounded-bottom': index === (collaborateurs.length-1) }">
                        <a class="d-flex" v-bind:href="'/collaborateurs/' + collaborateur.id">
                          <div class="chat-user-info text-left">
                            <h6 class="text-truncate mb-0">{{ collaborateur.prenom }} {{ collaborateur.nom }}</h6>
                            <div class="last-chat">
                              <p class="mb-1">
                                <span class="" v-if="collaborateur.fonction">{{ collaborateur.fonction }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="collaborateur.emailPro">{{ collaborateur.emailPro }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="collaborateur.telBureau">{{ collaborateur.telBureau }}</span>
                              </p>
                              <p class="mb-1">
                                <span class="badge bg-success" v-if="collaborateur.telMobilePro">{{ collaborateur.telMobilePro }}</span>
                              </p>
                              <p v-if="collaborateur.isActif === false" class="mb-1">
                                <span class="badge bg-danger">Inactif</span> 
                              </p>
                            </div>
                          </div>
                        </a>
                        <!-- Options -->
                        <div class="dropstart chat-options-btn">
                          <a v-bind:href="'/collaborateurs/' + collaborateur.id" class="">
                            <i class="fas fa-chevron-right"></i>
                          </a>
                        </div>
                      </li>
                      </ul>
                    </li>




                    


                    
                  </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
        

      

    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'Crm',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      clients: [],
      organismes: [],
      contacts: [],
      collaborateurs: [],
      listTitle: "Recherche en cours",
      search: null,
      isSearch: false,
      total: 0,
    }
  },
  components: {
    Header,
    Footer,
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }
  },
  mounted() {
    
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    submitSearch: async function () {
      if(this.search.length > 0) {
        this.search = this.search.trim();
        this.isSearch = true;
        this.listTitle = 'Recherche en cours'
        await UserService.getAnnuaireSearch(this.search).then(
          response => {
            this.total = response.data['hydra:member'][0].length + response.data['hydra:member'][1].length + response.data['hydra:member'][2].length + response.data['hydra:member'][3].length;
            this.listTitle = this.total + ' résultat trouvé pour "' + this.search + '"';
            if(this.total > 1) {
              this.listTitle = this.total + ' résultats trouvés pour "' + this.search + '"';
            }
            this.clients = response.data['hydra:member'][0];
            this.organismes = response.data['hydra:member'][1];
            this.contacts = response.data['hydra:member'][2];
            this.collaborateurs = response.data['hydra:member'][3];
            console.log(response.data['hydra:member'])
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
    },
    restartSearch: function () {
      this.isSearch = false;
    },
    emptySearch: function () {
      this.search = null;
      this.isSearch = false;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
    width: 100%;
    height: 100%;
    max-height: 80px;
    max-width: 80px;
    vertical-align: middle;
    -o-object-fit: cover;
    object-fit: cover;
}
.rounded-circle {
    border-radius: 50% !important;
}
</style>
