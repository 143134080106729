<template>
  <div>

    <Header />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded" style="margin-top:70px;">
            <li class="breadcrumb-item"><a href="/home"><i class="fas fa-chevron-left"></i><span class="ml-2">&nbsp;&nbsp;</span> Accueil</a></li>
            <li class="breadcrumb-item"><a href="/entreprise">Mon compte</a></li>
          </ol>
        </nav>
      </div>



      <div class="container mb-3">
        <div class="card user-info-card chat-user-list rounded-0">
          <div class="card-body d-flex">
            <div class="user-info text-left">
              <h6 class="mb-1 text-primary">{{ projet.code }} - {{ projet.nom }}</h6>
            </div>
          </div>
        </div>

        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group text-left">
            <li v-if="projet.parent" class="list-group-item"><strong>Parent : </strong> <a :href="'/projets/' + projet.parent.id">{{ projet.parent.code }} {{ projet.parent.nom }}</a></li>
            <li v-if="projet.demandeur" class="list-group-item"><strong>Demandeur : </strong> {{ projet.demandeur.prenom }} {{ projet.demandeur.nom }}</li>
            <li v-if="projet.pilote" class="list-group-item"><strong>Pilote : </strong> {{ projet.pilote.prenom }} {{ projet.pilote.nom }}</li>
            <li class="list-group-item"><strong>Date début : </strong> {{ projet.dateDebut|formatDate }}</li>
            <li class="list-group-item"><strong>Echéance : </strong> {{ projet.echeance|formatDate }}</li>
            <li v-if="projet.detail" class="list-group-item"><strong>Détail : </strong> {{ projet.detail }}</li>
            <li class="list-group-item d-flex justify-content-between">
              <div class="d-flex"><b>Actions : </b> <span class=" ms-2 badge " v-bind:class="actions.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ actions.length }}</span></div>
              <a href="#showActions" class="ml-2" data-bs-toggle="collapse" aria-expanded="false" aria-controls="showActions"><i class="fas fa-chevron-down fas-modify"></i></a>
              
            </li>
            <li id="showActions" class="collapse list-group-item">
              <div v-if="actions.length > 0">
                  <ul class="ps-0 chat-user-list rounded-0">
                    <div v-for="(action) in actions" :key="action.id">
                      <li class="p-3 chat-unread" v-bind:class="action.isClosed === true ? 'bg-light' : ''">
                        <div class="chat-user-info text-left">
                          <h6 class="text-truncate mb-0">{{ action.code }} - {{ action.nom }}</h6>
                          <div class="last-chat w-100">
                            <span class="badge bg-warning me-2">{{ action.echeance|formatDate }}</span>
                            <span class="badge bg-primary me-2">{{ action.demandeur.prenom }} {{ action.demandeur.nom }}</span>
                            <span class="badge bg-primary me-2">{{ action.pilote.prenom }} {{ action.pilote.nom }}</span>
                            <span v-if="action.isClosed === true" class="badge bg-danger">Clôturée</span>
                          </div>
                        </div>
                        <div class="dropstart chat-options-btn">
                          <a :href="'/actions/' + action.id" style="float: right;" class="mb-4 text-primary"><i class="fas fa-chevron-right"></i></a>   
                        </div>
                      </li>
                    </div>
                  </ul>
                  </div>
                  <div v-else class="alert alert-warning w-100">Aucune action pour ce projet !</div>
            </li>

            <li v-if="enfants.length > 0" class="list-group-item d-flex justify-content-between">
              <div class="d-flex"><b>Sous-projets : </b> <span class=" ms-2 badge " v-bind:class="enfants.length > 0 ? 'bg-primary' : 'bg-secondary'">{{ enfants.length }}</span></div>
              <a href="#showEnfants" class="ml-2" data-bs-toggle="collapse" aria-expanded="false" aria-controls="showEnfants"><i class="fas fa-chevron-down fas-modify"></i></a>
              
            </li>
            <li id="showEnfants" class="collapse list-group-item">
              <div v-if="enfants.length > 0">
                  <ul class="ps-0 chat-user-list rounded-0">
                    <div v-for="(projet) in enfants" :key="projet.id">
                      <li class="p-3 chat-unread" v-bind:class="projet.isDone === true ? 'bg-light' : ''">
                        <div class="chat-user-info text-left">
                          <h6 class="text-truncate mb-0">{{ projet.code }} - {{ projet.nom }}</h6>
                          <div class="last-chat w-100">
                            <span class="badge bg-warning me-2">{{ projet.echeance|formatDate }}</span>
                            <span class="badge bg-primary me-2">{{ projet.demandeur.prenom }} {{ projet.demandeur.nom }}</span>
                            <span class="badge bg-primary me-2">{{ projet.pilote.prenom }} {{ projet.pilote.nom }}</span>
                            <span v-if="projet.isDone === true" class="badge bg-danger">Clôturé</span>
                          </div>
                        </div>
                        <div class="dropstart chat-options-btn">
                          <a :href="'/projets/' + projet.id" style="float: right;" class="mb-4 text-primary"><i class="fas fa-chevron-right"></i></a>   
                        </div>
                      </li>
                    </div>
                  </ul>
                  </div>
                  <div v-else class="alert alert-warning w-100">Aucun sous-projet !</div>
            </li>
            <li class="list-group-item"><strong>Statut : </strong>  <ProjetStatut :statut="projet.statut"/></li>

          </ul>
        </div>

      </div>




    </div>

    <Footer />

  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';
import ProjetStatut from '../components/ProjetStatut.vue';

export default {
  name: 'Projet',
  data: function () {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      projet: [],
      actions: [],
      enfants: [],
    }
  },
  components: {
    Header,
    Footer,
    ProjetStatut
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  },
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    if (this.loggedIn) {
      await UserService.getProjet(this.$route.params.id).then((response) => {
        this.projet = response.data;
        console.log(this.projet);
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

      await UserService.getProjets('sousProjet&projetId=' + this.$route.params.id).then((response) => {
        this.enfants = response.data['hydra:member'];
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

      await UserService.getActions('projet&projetId=' + this.$route.params.id).then((response) => {
        this.actions = response.data['hydra:member'];
      },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
  },
  mounted() {

  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },

  },
  watch: {
    '$route.query.type': {
      handler: function (type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}

.avatar-img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 80px;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}</style>
